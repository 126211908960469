import { useStaticQuery, graphql } from "gatsby"

export default function useQueryCategories() {
  return useStaticQuery(
    graphql`
      {
        allWpCategory {
          edges {
            node {
              id
              parentId
              slug
              uri
              name
              wpChildren {
                nodes {
                  id
                  slug
                  uri
                  name
                  wpChildren {
                    nodes {
                      id
                      slug
                      uri
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
}
