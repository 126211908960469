/**
 *
 * Use recursive to find ancestor of something
 *
 * Result has returned like this [grandFather, father, child,...]
 * @param {Array.<{id: String, parentId: String, [key]: any}>} data
 * @param {String} id
 * @returns
 */
function findAncestor(data, id, result = []) {
  if (!id || !Array.isArray(data) || !Array.isArray(result)) return result
  let i = 0
  while (i < data.length) {
    if (data[i]?.id === id) {
      result.unshift(data[i])
      if (data[i].parentId) {
        findAncestor(data, data[i].parentId, result)
      }
      break
    }
    i++
  }
  return result
}

export { findAncestor }
