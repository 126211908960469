import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout-page"
import { domain } from "../constants/index"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import useQueryThemeOptions from "../hooks/use-query-themeOptions"
import styled from "styled-components"
import BlogBlock from "../components/acf/blog"
import useQueryCategories from "../hooks/use-query-categories"
import { findAncestor } from "../utils/findAncestor"
import { Link } from "gatsby"
const PostDetail = ({ data, pageContext, serverData, location, ...props }) => {
  const {
    id,
    title,
    date,
    content,
    // status,
    // slug,
    featuredImage,
    categories,
    excerpt,
    uri,
    seo,
    // dateGmt,
    // modifiedGmt,
  } = data.wpPost
  console.dir(data.wpPost)
  const { logo } = useQueryThemeOptions().wp.themeOptions.themeOptions || {}
  let titleSubMenu
  const dataUseQueryCategories = useQueryCategories().allWpCategory.edges.map(
    ({ node }) => node
  )
  const category = categories.nodes[0] || {}
  const ancestorOfCategory = findAncestor(dataUseQueryCategories, category.id)
  if (ancestorOfCategory[0]) {
    titleSubMenu = {
      title: ancestorOfCategory[0].name,
      url: "/blog/" + (ancestorOfCategory[0]?.slug || ""),
    }
  }
  const postsSuggests = getPostsSuggestion(categories.nodes, [id], 4)
  const descSEO = String(excerpt)
    .replace(/<[^>]+>/g, "")
    .slice(0, 150)
  return (
    <Layout>
      <GatsbySeo
        title={seo.title || title}
        description={seo.metaDesc || descSEO}
        titleTemplate="%s"
        openGraph={{
          type: seo.opengraphType,
          title: seo.title || title,
          description: seo.metaDesc || descSEO,
          url: domain + "/blog" + uri,
          images: [{ url: featuredImage?.node?.sourceUrl || logo?.sourceUrl }],
        }}
      />
      <GenericContent className="generic-content py-6">
        <div className="container mx-auto px-2 ">
          <h1 className="page-title mb-2">{title}</h1>
          <div className="date mb-5">
            Posted on {date}
            <Link
              className="text-decoration-none text-blue-100"
              to={titleSubMenu.url}
            >
              <span className="mx-2 text-dark-100">|</span> {titleSubMenu.title}
            </Link>
          </div>
          {/* <div className="feature-image">
              {
                featuredImage?.node ? (
                  <GatsbyImage
                    image={getImage(featuredImage.node.localFile)}
                    alt={featuredImage.node.altText}
                    title={featuredImage.node.title}
                  />
               
                ) : null
              }
              
            </div> */}
          <div
            className="content my-5"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </GenericContent>
      <div className="container mx-auto px-2">
        <div className="border-t-2 border-solid pb-6 divide-blue-300 "></div>
        <h3 className="mb-5">Other posts</h3>
      </div>

      <div className="mb-6">
        <BlogBlock recentPosts={Object.values(postsSuggests)} blogCount={3} />
      </div>
    </Layout>
  )
}

export default PostDetail
const GenericContent = styled.div`
  ul,
  ol {
    // list-style: inside;
    li + li {
      margin-top: 0.5rem;
    }
  }
  ol {
    list-style-type: decimal;
  }
  blockquote {
    padding-left: 1rem;
    border-left: 5px solid #1a1a1a;
  }
`

export const query = graphql`
  query PostQuery($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      date(formatString: "dddd, MMMM DD, YYYY")
      content
      status
      slug
      excerpt
      uri
      dateGmt
      modifiedGmt
      seo {
        title
        metaDesc
        opengraphType
      }
      featuredImage {
        node {
          title
          sourceUrl
          altText
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
      categories {
        nodes {
          id
          parentId
          name
          posts {
            nodes {
              ...postFragment
            }
          }
        }
      }
    }
  }
`

function getPostsSuggestion(categories = [], idsPostIgnore = [], quantity = 4) {
  let _posts = {}
  categories.forEach(({ posts }) => {
    if (Object.keys(_posts).length >= quantity) return
    posts.nodes.forEach(post => {
      if (Object.keys(_posts).length >= quantity) return
      if (!posts[post.id] && !idsPostIgnore.includes(post.id)) {
        _posts[post.id] = post
      }
    })
  })
  return _posts
}
