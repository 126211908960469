import * as React from "react"
import BlockSection from "../common/block"
import Post from "../common/post"

/**
 * @param {Object} props
 * @param {String} props.heading
 * @param {String} props.layout
 * @param {Number} props.blogCount
 * @param {String} props.headingAlignment
 * @param {String} props.mobileLayout
 * @param {Object} props.link
 * @param {Array} props.selectBlog
 * @param {Array} props.recentPosts
 * @returns
 */
export default function BlogBlock(props) {
  const {
    loopArgumentType, // recent-blog | select-blog | category
    category,
    selectBlog,
    recentPosts,
    blogCount,
  } = props || {}
  let dataPosts;

  const numPosts = blogCount

  if(loopArgumentType === 'category') {
    dataPosts = category?.posts?.nodes?.slice(0, numPosts)
  } else if(loopArgumentType === 'select-blog') {
    dataPosts = selectBlog
  } else { 
    dataPosts = recentPosts?.slice(0, numPosts) || []
  }

  return (
    <>
     <BlockSection className="list-post mt-0">
          <div className="container mx-auto px-2">
            {dataPosts.length ? (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 md:gap-6 gap-5 ">
                {dataPosts.map((post, index) => (
                  <Post key={post.id + index} data={post} />
                ))}
              </div>
            ) : null}
            
          </div>
        </BlockSection>
    </>
  )
}
