import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ArticleContent = styled.div`
  .ratio:before {
    // padding-top: 160%;
  }
`
const Title = styled.h4``

const Text = styled.div`
  
`
/**
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {String} props.data.id
 * @param {String} props.data.slug
 * @param {String} props.data.title
 * @param {String} props.data.excerpt
 * @param {String} props.data.date
 * @param {Object} props.data.featuredImage
 * @returns
 */
const Post = props => {
  const { uri, title, excerpt, featuredImage } = props.data || {}
  const desc = String(excerpt).replace(/<[^>]+>/g, '').slice(0, 85) + '...'

  return (
    <>
      <ArticleContent className={"article"}>
        <div className="article__content">
          <Link className="article__image ratio ratio-16x9 relative"  to={'/blog' + uri}>
            <img src={featuredImage.node.sourceUrl} alt={featuredImage.node.altText} />
          </Link>
          <div className="article__body mt-5 mt-md-0">
            <Title className="border-b-2 border-solid pb-2  divide-blue-300 font-bold">
              <Link className="text-decoration-none" to={'/blog' + uri}>
                {title}
              </Link>
            </Title>
            <Text className="article__text opacity-70">{desc}</Text>
          </div>
        </div>
      </ArticleContent>
    </>
  )
}

export default Post

