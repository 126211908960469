import * as React from "react"
const Block = props => {
  return (
    <section {...props} className={"block-tpl " + props.className}>
      {props.children}
    </section>  
  )
}

export default Block
